


















































import { Configuracion } from "@/shared/dtos/Configuracion";
import ConfiguracionModule from "@/store/modules/Configuracion-module";
import { Component, Vue, PropSync } from "vue-property-decorator";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class VentanaFacturacionResumida extends Vue {
  @PropSync("dialog") dialogSync!: boolean;
  public configuracion: Configuracion = new Configuracion();

  public async created() {
    await ConfiguracionModule.getConfiguracion(
      ConfiguracionModule.ConfiguracionForBack.id
    );
    this.configuracion = ConfiguracionModule.Configuracion;
  }
  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        ConfiguracionModule.modificarConfiguracion(this.configuracion).then(
          () => {
            this.dialogSync = false;
          }
        );
      }
    });
  }
}
